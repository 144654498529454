import React from "react";
import styled from "styled-components";
import LinkButton from "./LinkButton";

const PagerWrapper = styled.div`
  text-align: center;
  padding: 1rem;
`;

export default function Pager({ nextUrl, previousUrl }) {
  return (
    <PagerWrapper>
      {previousUrl && <LinkButton href={previousUrl}>&lt; Previous</LinkButton>}
      {nextUrl && <LinkButton href={nextUrl}>Next &gt;</LinkButton>}
    </PagerWrapper>
  );
}
