import React from "react";
import AppBase from "../components/AppBase";
import { graphql } from "gatsby";
import BlogArticle from "../components/BlogArticle";
import Pager from "../components/Pager";
export default function IndexPage({ data, pageContext }) {
  const nodes = data.allMarkdownRemark.edges.map(({ node }) => node);
  return (
    <AppBase subPage={pageContext.subPage}>
      {nodes.map((node, index) => {
        const {
          frontmatter: { title, url, date, categories },
          excerpt,
          html
        } = node;
        return (
          <BlogArticle
            key={index}
            title={title}
            url={url}
            date={date}
            bodyHtml={excerpt}
            categories={categories}
            showReadMore={html.length !== excerpt.length}
          />
        );
      })}
      <Pager
        nextUrl={pageContext.nextUrl}
        previousUrl={pageContext.previousUrl}
      />
    </AppBase>
  );
}

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $categories: [String]) {
    allMarkdownRemark(
      filter: {
        fields: { pageType: { eq: "markdown-posts" } }
        frontmatter: { categories: { in: $categories } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          html
          excerpt(format: HTML, pruneLength: 300)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            url
            categories
          }
        }
      }
    }
  }
`;
