var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var buttonColorOptions = {
    primary: "orange",
    secondary: "gray"
};
var UIButton = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0.5rem 1rem;\n  background-color: ", ";\n  display: inline-block;\n  cursor: pointer;\n  color: white;\n  border-radius: 1rem;\n\n  :hover {\n    text-decoration: none;\n  }\n\n  & + & {\n    margin-left: 1rem;\n  }\n"], ["\n  padding: 0.5rem 1rem;\n  background-color: ", ";\n  display: inline-block;\n  cursor: pointer;\n  color: white;\n  border-radius: 1rem;\n\n  :hover {\n    text-decoration: none;\n  }\n\n  & + & {\n    margin-left: 1rem;\n  }\n"])), function (props) { return buttonColorOptions[props.use || "secondary"]; });
export default UIButton;
var templateObject_1;
