import styled from "styled-components";
import { navigate } from "gatsby";
import UIButton from "jecko-components/buttons/UIButton";

export default styled(UIButton).attrs(props => ({
  ...props,
  onClick: evt => {
    evt.preventDefault();
    navigate(props.href);
  }
}))``;
